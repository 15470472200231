.banner-bg {
  position: relative;
  height: 90vh;
  width: 100%;
  overflow-x: hidden;
  /* background-color: #0e091b; */
  background-color: rgb(19, 19, 19);
}
.modal-backcss {
  background-color: #000000d1;
}
.flex-bannerimg {
  /* position: relative; */
  /* width: 100%; */
  /* height: 100%; */
}
.suggestion-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  background-color: rgb(19, 19, 19) !important;
  margin-bottom: 2px;
  gap: 4px;
  cursor: pointer;
}
.suggestion-name {
  font-size: 16px !important;
  font-weight: 600 !important;
}
.input-suggestion {
  /* background-color: grey; */
}
.suggestion-img img {
  margin-right: 6px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: white;
}

.logo-img {
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  cursor: pointer;
  filter: blur(10px);
  animation: tokens-animation 4s ease-in-out alternate infinite;
  transition: transform 0.3s ease-in-out, filter 0.3s ease-in-out;
  transform: scale(1);
  box-shadow: 2px 2px 20px rgb(255, 255, 255);
  /* z-index: 22; */
  z-index: 0;
}
@keyframes tokens-animation {
  0% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}
.logo-info {
  display: none;
  position: absolute;
  top: 34%;
  left: 100%;
  /* background-color: rgba(0, 0, 0, 0.8); */
  color: #c738ff;
  font-weight: 700;
  font-size: large;
  padding: 5px 10px;
  border-radius: 5px;
  white-space: nowrap;
  text-align: center;
  opacity: 0;
  transform: translateX(-10px);
  transition: opacity 0.5s ease-in-out;
}

/* .logo-img:hover .logo-info {
  display: block;
  opacity: 1;
  transform: rotate(0deg);
}
.logo-img:hover {
  filter: blur(0px);
  transform: scale(1.4) rotate(-5deg);
  transition: all 0.5s ease-in-out;
  box-shadow: none;
} */
.logo-1 {
  background-image: url("../../Assets/tinified/token-17.png");
  top: 10%;
  left: 20%;
  width: 60px;
  height: 60px;
}

.logo-2 {
  background-image: url("../../Assets/tinified/token-8.png");
  top: 40%;
  left: 60%;
}

.logo-3 {
  background-image: url("../../Assets/tinified/token-20.png");
  top: 14%;
  left: 90%;
}
.logo-4 {
  background-image: url("../../Assets/tinified/token-3.png");
  top: 40%;
  left: 0%;
}

.logo-5 {
  background-image: url("../../Assets/tinified/token-13.png");
  top: 60%;
  width: 50px;
  height: 50px;
  left: 90%;
}

.logo-6 {
  background-image: url("../../Assets/tinified/token-4.png");
  top: 5%;
  left: 70%;
}
.logo-7 {
  background-image: url("../../Assets/tinified/token-14.png");
  top: 80%;
  left: 50%;
  width: 60px;
  height: 60px;
}

.logo-8 {
  background-image: url("../../Assets/tinified/token-5.png");
  top: 70%;
  left: 14%;
}

.logo-9 {
  background-image: url("../../Assets/tinified/token-10.png");
  top: 0%;
  left: 40%;
  width: 50px;
  height: 50px;
}
.logo-10 {
  background-image: url("../../Assets/tinified/token-9.png");
  top: 40%;
  width: 50px;
  height: 50px;
  left: 30%;
}
.main-div {
  /* z-index: 111; */
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
  gap: 1rem;
}
.div-table {
  /* padding: 50px 0; */
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: transparent;
}
.main-div h1 {
  background-color: transparent;
  z-index: 1;
  opacity: 1;
  font-size: 3.4rem;
  text-align: center;
}
.table-swap {
  /* display: flex; */
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
  z-index: 1;
  padding: 10px;
  /* border: 1px solid grey; */
  /* z-index: 111; */
  border-radius: 20px;
}
.swap-value {
  background-color: #000000a8 !important;
  border-radius: 20px;
  padding: 25px 15px;
  width: 30rem;
  border: 1px solid rgb(67, 67, 67);
}
.swap-value div,
.swap-value span {
  background-color: transparent;
  /* color: rgb(144, 144, 144); */
}
.grey-color {
  color: rgb(179, 179, 179);
  font-weight: 700;
}
.swap-value div {
  font-size: 16px;
  /* font-size: small; */
}
.enter-val {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
}
#number-input,
#number-input-send {
  background: none;
  border: none;
  font-size: 2.3rem;
  width: 90%;
  font-weight: 600;
}
#number-input[type="number"]::-webkit-inner-spin-button,
#number-input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
#number-input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
#number-input::placeholder {
  color: rgb(117, 117, 117);
}
#number-input:focus-visible {
  outline: none;
}
.modal-backdrop {
  z-index: 0;
}
.modal-content {
  background: #131617 !important;
  /* padding: 30px 0; */
  border-radius: 30px !important;
  /* margin-top: 4%; */
}
.modal-header {
  z-index: 111;
}
.modal-body {
  font-weight: 400;
}
.modal.show .modal-dialog {
  /* height: 90vh !important; */
}
.modal-img-btn {
  width: 18px;
  height: 20px;
  border-radius: 50%;
}
.flex-class {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.btn-modal-backdrop,
.backdrop-btn-css {
  font-weight: 600;
  border: 1px solid rgb(238 238 238);
  border-radius: 30px;
}
.btn-modal-backdrop:hover,
.backdrop-btn-css:hover {
  border: 1px solid rgb(238 238 238);
  background-color: rgba(59, 59, 59, 0.435);
}
.grey-clr {
  color: grey;
}
.get-started-btn button {
  /* width: 100%;
  padding: 10px;
  margin-top: 10px;
  border-radius: 20px;
  border: none;
  border: 1.4px solid transparent;
   background-color: #54186be8;
  font-weight: 600; */

  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px;
  margin-top: 1rem !important;
  margin-top: 10px;
  border-radius: 10px;
  justify-content: center;
  border: 1.4px solid transparent;
  /* background-color: #54186ba4; */
  background-color: #00a8e8;
  box-shadow: 0px 0px 5px #07e3f8;
  color: #ffffff !important;
  font-weight: 600;
  margin: 0 auto;
}
.get-started-btn button:hover {
  /* border: 1.4px solid #b700ff; */
  border: 1.4px solid #ffffff;
  /* border: 1.4px solid #0f273a; */
  color: #fff !important;
  box-shadow: 0px 0px 5px #000000;
}
.swap-value {
  transition: transform 0.5s ease-in-out; /* Smooth transition */
}

.swapped {
  transform: translateY(100%); /* Move down by 100% of its height */
}
.last-span-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  font-size: smaller;
}
#buy-div.swapped {
  transform: translateY(-100%); /* Move up by 100% of its height */
}
.token-suggestions-data {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.contract-add-model {
  font-size: smaller !important;
}
@media (min-width: 550px) and (max-width: 990px) {
  .logo-10,
  .logo-2 {
    display: none;
  }
  .logo-9 {
    top: 31%;
  }
  .logo-1 {
    top: 6%;
    left: 13%;
  }
}

@media (max-width: 550px) {
  .logo-3,
  .logo-2,
  .logo-10 {
    display: none;
  }
  .swap-value {
    width: 20rem;
  }
  .last-span-div {
    width: 100%;
    padding: 0 30px;
    text-align: center;
  }
  .main-div h1 {
    font-size: 2.4rem;
  }
  #number-input {
    font-size: 1.5rem;
  }
  .model-data-flex {
    width: 90%;
  }
  .model-data-flex .suggestion-name {
    width: 85%;
  }
  .suggestion-img img {
    margin-right: 3px;
    width: 30px;
    height: 30px;
  }
  .contract-add-model {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 90%;
  }
}
@media (max-width: 350px) {
  .swap-value {
    width: 16rem;
  }
  .swap-value div,
  .buy-swap-value-div .button-div-send {
    font-size: small !important;
  }
}
.get-started-btn p {
  display: none;
  color: #ffffff;
  text-align: center;
  font-weight: 500;
}

.get-started-btn.disabled p {
  display: block;
}
