.mini-loader {
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main-loader-circle .spinner-grow {
  height: 14px;
  width: 14px;
  background-color: aliceblue !important;
}
.main-loader-circle {
  display: flex;
  align-items: center;
  justify-content: center;
}
