.footer-pg {
  padding: 10px 0 30px 0;
}

.social-icons i {
  margin-right: 2rem;
}
.footer-links-heading div {
  padding-bottom: 7px;
  color: #fff;
}
.footer-links-heading a {
  color: grey;
  text-decoration: none;
}
.footer-links-heading {
  text-align: end;
}
.footer-soc {
  padding-bottom: 1rem;
  /* padding-top: 2rem; */
  /* border-bottom: 1px solid rgb(71, 71, 71); */
}
.footer-flex {
  font-size: small;
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  align-items: center;
  /* border-top: 1px solid rgb(71, 71, 71); */
}
.footer-sub-pg {
  border-top: 1px solid rgb(71, 71, 71);
}
@media (max-width: 550px) {
  .social-icons {
    padding: 30px 0;
    text-align: right;
  }
  .social-icons i {
    margin-right: 1rem;
  }
  .social-icons i:last-child {
    margin-right: 0rem !important;
  }
  .footer-links-heading {
    text-align: right;
  }
  .footer-links-heading a {
    font-size: small;
  }
  .footer-links-heading div {
    padding-bottom: 3px;
    font-size: smaller;
  }
  .footer-flex {
    padding: 10px 0 10px 5px;
    white-space: nowrap;
    font-size: x-small;
    text-align: center;
    display: block;
    /* border-top: none !important; */
  }
  .footer-pg {
    padding: 10px 0 20px 0;
  }
}
/* @media (max-width: 1024px) {
  .footer-flex {
    border-top: none !important;
  }
} */
