.section-3-pg {
  padding-top: 40px;
  padding-bottom: 60px;
}
.section-3-pg h1 {
  padding-bottom: 1.4rem;
}
.connect-us-box-1 {
  background-color: rgba(255, 77, 0, 0.178);
  color: rgb(255 59 26) !important;
  height: 100%;
}
.connect-us-box-1 div,
.connect-us-box-2 div,
.connect-us-box-3 div {
  font-size: 1.5rem;
  font-weight: 600;
  padding-top: 2rem;
}
.connect-us-box-1 .icon-button {
  color: rgb(255 59 26) !important;
  font-weight: 700;
}
.connect-us-box-2 {
  background-color: rgb(98 84 50 / 16%);
  color: rgb(141 137 106) !important;
}
.connect-us-box-2 .icon-button {
  color: rgb(141 137 106) !important;
  font-weight: 700;
}
.connect-us-box-3 {
  height: 100%;
  background-color: #361a37 !important;
  color: #ff40f8 !important;
}
.connect-us-box-3 .icon-button {
  color: #ff40f8 !important;
  font-weight: 700;
}
@media (max-width: 880px) {
  .connect-us-box-2,
  .connect-us-box-3 {
    margin-top: 20px;
  }
}
