.migrate-pg {
  width: 60%;
  margin: 0 auto;
  color: #fff;
  border: 1px solid rgb(128 128 128);
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  border-radius: 20px;
  padding: 20px;
  margin-top: 20px;
}

.migrate-pg-heading {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.migrate-pg-body {
  text-align: center;
  padding-top: 2rem;
}
.v2-list {
  color: grey;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fa-arrow-left {
  cursor: pointer;
}
.fa-info-circle {
  color: grey;
}
.migrate-pg-relative {
  position: relative;
}
@media (max-width: 660px) {
  .migrate-pg {
    width: 96%;
  }
}
