.section-2-pg {
  padding-top: 40px;
  padding-bottom: 60px;
}
.bg-section-2-box {
  background-color: rgba(155, 155, 155, 0.086);
  border-radius: 20px;
  height: 100%;
  padding: 20px;
  display: flex;
  align-items: left;
  justify-content: space-between;
  flex-direction: column;
  gap: 1.6rem;
}
.bg-section-2-box span {
  font-weight: 500;
  color: rgb(146, 146, 146);
  font-size: large;
}
.bg-section-2-box div {
  color: rgb(255, 255, 255);
  font-size: 3.2rem;
  font-weight: 500;
}
.bottom-div-section-2 h5 {
  font-weight: 400;
  padding-top: 6%;
  font-size: 18px;
}
.bottom-div-section-2 button {
  border: none;
  background-color: rgba(155, 155, 155, 0.226);
  border: 2px solid transparent;
  padding: 8px 22px;
  color: #fff;
  border-radius: 20px;
  margin-top: 10px;
  font-weight: 600;
}
.bottom-div-section-2 button:hover {
  border: 2px solid rgba(155, 155, 155, 0.226);
  background-color: transparent;
  transition: all 0.4s ease-in-out;
}

@media (max-width: 880px) {
  .col-half-div-2 {
    margin-top: 30px;
    /* height: min-content; */
  }
  .section-2-pg .col-lg-3 {
    margin-top: 20px;
    gap: 0rem;
  }

  .col-half-div-3 {
    margin-top: 60px;
  }
  .bg-section-2-box div {
    font-size: 2.5rem;
  }
}
@media (max-width: 550px) {
  .bottom-div-section-2 h5 {
    padding-top: 0%;
    font-size: 14px;
  }
  .bg-section-2-box div {
    font-size: 2rem;
  }
  .bg-section-2-box {
    gap: 0rem;
  }
}
