.add-liquidity-pg {
  padding-top: 2px;
}
.remove-liquidity-page {
  height: 100vh !important;
}
.createpositons-pg {
  /* width: 86%;
  margin: 0 auto;
  color: #fff;
  border: 1px solid rgb(59, 59, 59);
  border-radius: 16px;
  padding: 20px;
  margin-top: 20px; */

  width: 86%;
  margin: 0 auto;
  color: #fff;
  border: 1px solid rgb(59, 59, 59);
  border-radius: 16px;
  padding: 20px;
  margin-top: 20px;
  padding: 20px;
  /* margin: 0 auto; */
  /* border: 3px solid rgba(0, 0, 0, 0.568); */
  border: 2px solid #44617d;
  /* box-shadow: 2px 2px 8px #1e2b37; */
  box-shadow: 2px 2px 10px black;
  border-radius: 10px;
  background-color: #112130 !important;
}
.create-liquidyt-relative {
  position: relative;
}
.remove-liquidity-pg {
  width: 70%;
  margin-top: 13%;
  height: 100%;
  position: relative;
}
.view-positions-pg {
  height: 100%;
  background-color: rgb(19, 19, 19);
  /* background-color: #0e091b; */
}
.backdrop-no-modal-btn {
  font-weight: 600;
  /* border: 1px solid rgb(92, 92, 92); */
  border-radius: 30px;
  padding: 10px 20px;
  background-color: rgb(114 114 114 / 10%);
  color: rgb(255, 255, 255);
}
.select-token-btn .modal-btn-back-css {
  color: #ffffff !important;
  font-weight: 600;
  border: 1px solid #07e3f8;
  padding: 10px 20px;
  background-color: rgb(114 114 114 / 10%) !important;
  color: rgb(255, 255, 255);
  width: 100%;
}
.migrate-pg-body {
  height: 100%;
  /* background-color: #0e091b; */
  /* background-color: rgb(19, 19, 19); */
}
.clear-all-btn button {
  background-color: transparent;
  border: none;
  color: rgb(0, 242, 255);
  font-size: small;
  text-decoration: underline;
}
.migrate-pg-body p {
  text-align: left;
  font-weight: 600;
}
.div-add-positons-btn {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.div-add-positons-btn .btn-modal-backdrop,
.div-add-positons-btn .backdrop-btn-css {
  width: 100%;
}
.add-postions-form-btn {
  width: 100%;
}

.selected-token-btn button .flex-class {
  justify-content: space-between !important;
  align-items: center;
}
.createpositons-pg .btn-modal-backdrop,
.createpositons-pg .backdrop-btn-css {
  border-radius: 10px;
}
.selected-token-btn .flex-class .fa-angle-down {
  text-align: right;
}
.select-token-btn .btn-modal-backdrop,
.select-token-btn .backdrop-btn-css {
  /* background-color: #54186be8;
  font-weight: 600;
  border: 1px solid transparent; */
  box-shadow: 0px 0px 5px #07e3f8;
  background-image: linear-gradient(to right, #07e3f8, #026cdc);
  color: #ffffff !important;
  font-weight: 600;
  border: 1px solid #07e3f8;
}
.select-token-btn .btn-modal-backdrop:hover,
.select-token-btn .backdrop-btn-css:hover {
  background-color: #54186b82;
  border: 1px solid white;
}
.fee-tier-div {
  background-color: transparent;
  border: 1px solid rgb(59, 59, 59);
  border-radius: 10px;
  padding: 20px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.fee-tier-div p {
  margin-bottom: 0;
}
.fee-tier-div p:nth-child(2) {
  font-size: small;
  color: gray;
}
.fee-tier-div div:nth-child(2) {
  color: grey;
  cursor: pointer;
  font-weight: 600;
}
.fee-tier-div label span {
  color: grey;
  font-size: small;
}
.createpositons-pg .toggle-row .fee-tier-div {
  padding: 5px 20px !important;
}
.set-price-range {
  /* background-color: rgb(27, 27, 27);
  padding: 10px 20px;
  border-radius: 10px;
  color: rgb(166, 166, 166); */
  background-color: rgb(114 114 114 / 10%);
  padding: 10px 20px;
  border-radius: 10px;
  color: rgb(201 201 201);
  text-align: left;
  margin-top: 1rem;
}
.set-price-range div span {
  display: block;
}
.set-price-range div span:nth-child(1) {
  font-size: small;
}
.set-price-range div span:nth-child(2) {
  font-size: x-large !important;
}
#number-input-form {
  background: none;
  border: none;
  font-size: xx-large;
  width: 70%;
  font-weight: 600;
}
#number-input-form:focus-visible {
  outline: none;
}
#number-input-form-2:focus-visible {
  outline: none;
}
#number-input-form-2 {
  background: none;
  border: none;
  font-size: xx-large;
  width: 100%;
  font-weight: 600;
  /* padding-left: 20px; */
  margin: 10px 0;
}
.add-liquidity-heading {
  font-weight: 600;
}
.ethImg {
  width: 30px;
  height: 30px;
}
.set-deposit-amt {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ok-btn button {
  width: 16%;
  /* margin: 0 auto; */
  padding: 5px 20px;
  border-radius: 10px;
  margin-top: 0 !important;
}
.remove-btn button {
  width: 30%;
  padding: 8px 20px;
  border-radius: 10px;
}
.gradient-bg-circle {
  background: linear-gradient(#00c2ff, rgb(0, 131, 171));
  border-radius: 50%;
  width: 19rem;
  height: 22rem;
  position: absolute;
  top: 20%;
  overflow: hidden !important;
  right: 0%;
  filter: blur(15rem);
  z-index: 0;
}

.gradient-bg-circle-2 {
  background: linear-gradient(#00c2ff, rgb(0, 131, 171));
  border-radius: 50%;
  width: 27rem;
  height: 12rem;
  position: absolute;
  top: 40%;
  overflow: hidden !important;
  left: -10%;
  filter: blur(15rem);
  z-index: 0;
}
.create-pair-btn button {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px;
  margin-top: 1rem !important;
  margin-top: 10px;
  border-radius: 10px;
  justify-content: center;
  border: 1.4px solid transparent;
  /* background-color: #54186ba4; */
  background-color: #00cef0cc;
  box-shadow: 0px 0px 5px #07e3f8;
  /* background-image: linear-gradient(to right, #142a2c, #1b3c60); */
  color: #000000 !important;
  /* background-color: #112131; */
  font-weight: 600;
  margin: 0 auto;
  padding: 10px 20px;
  /* background-color: rgb(114 114 114 / 10%); */
  color: rgb(255, 255, 255);
}
.create-pair-btn button:disabled {
  border: 1.4px solid rgb(206, 200, 200);
  color: #a2a2a2 !important;
  background-color: rgb(206 200 200 / 28%);
  box-shadow: unset;
}
.create-pair-btn button:hover {
  border: 1.4px solid #0f273a;
  color: #fff !important;
  box-shadow: 0px 0px 5px #000000;
}
.create-pair-btn button:disabled:hover {
  border: 1.4px solid rgb(206, 200, 200);
  color: #a2a2a2 !important;
  background-color: rgb(206 200 200 / 28%);
  box-shadow: unset;
}
@media (max-width: 550px) {
  .createpositons-pg {
    width: 100%;
  }
  .remove-btn button {
    width: 50%;
  }
  .add-liquidity-pg {
    padding: 2px 0 40px 0;
  }
  .select-token-btn .add-liquidity-sel-token {
    padding: 7px 10px;
  }
  .add-pos-btn {
    width: 70%;
  }
  .gradient-bg-circle-2 {
    width: 5rem;
    height: 2rem;
    display: none;
  }
  .gradient-bg-circle {
    display: none;
  }
}
