#number-input-send {
  background: none;
  border: none;
  font-size: 6rem;
  width: 100%;
  font-weight: 600;
  text-align: center;
}
.section-bg-pages {
  height: 91.5vh !important;
  /* background-color: #0e091b; */
  background-color: rgb(19, 19, 19);
}
.send-swap-val .enter-val {
  width: 100%;
  text-align: center;
}
.button-div-send {
  margin-top: 1.2rem;
  text-align: center;
}
#number-input-send-div2 {
  font-size: small;
  background: none;
  border: none;
  width: 100%;
  font-weight: 600;
}
.send-swap-val-2 .enter-val {
  display: block;
}
.send-swap-val-2 .enter-val input {
  padding: 10px 0;
}
.send-swap-val-2 .enter-val input:focus-visible {
  outline: none;
  padding: 10px 0;
}
.buy-swap-value input:focus-visible,
.send-swap-val input:focus-visible {
  outline: none;
}
@media (max-width: 880px) {
  .section-bg-pages {
    height: 100% !important;
  }
}
