/* .navbar-nav .nav-link.active,
.navbar-nav .nav-link.show, */

.navbar {
  /* position: sticky;
  top: 0;
  z-index: 9; */
  background-color: rgb(19, 19, 19);
  /* position: sticky; */
  /* top: 0; */
  /* z-index: 2; */
  /* background-color: #0e091b; */
}
.select-lang-btn .dropdown-item:focus,
.dropdown-item:hover {
  /* background-color: #112131; */
  background-color: #232323;
}
.select-lang-btn {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-right: 10px;
}
.language-toggle {
  display: flex;
  gap: 0px;
}

.language-button {
  padding: 5px 14px;
  border: 0.5px solid #a7a7a779;
  cursor: pointer;
  border-radius: 5px 0 0 5px;
  background-color: transparent;
  transition: background-color 0.3s ease;
  color: #fff;
}
.language-btn-chinese {
  border-radius: 0 5px 5px 0;
}

.language-button.active {
  background-color: #112131;
  color: #00c2ff !important;
}

.language-button:hover {
  background-color: #00c3ff1e;
}

.navbar-brand {
  /* color: #b700ff !important; */
  color: #00c2ff !important;
  font-weight: 600;
}
.navbar-brand img {
  width: 100%;
}
.nav-link {
  color: #b2b2b2 !important;
  font-weight: 500;
}
.nav-link:hover {
  color: #fff !important;
}
.hover-nav {
  display: none;
  font-size: smaller;
  z-index: 2;
}
.nav-explore:hover {
  cursor: pointer;
}
.nav-explore:hover .hover-nav {
  display: block;
  position: absolute;
  top: 3rem;
  z-index: 4;
}
.hover-nav li {
  list-style: none;
  background-color: rgb(19, 19, 19);
}
.nav-explore ul {
  background-color: rgb(59, 59, 59);
  padding: 0 0.3rem !important;
  border-radius: 10px;
}
.nav-explore ul li:hover {
  color: #fff;
  background-color: rgb(29 29 29 / 73%);
}
.nav-explore a {
  width: 8rem;
  color: grey;
}
.btn-connect-wallet {
  /* color: #b700ff !important;
  border: 2px solid transparent;
  font-weight: 500;
  background-color: #54186ba4;
  border-radius: 16px;
  font-size: var(--bs-nav-link-font-size);
  font-weight: 500 !important; */

  border: 2px solid #00c2ff;
  font-weight: 700;
  background-color: #54186ba4;
  border-radius: 12px;
  font-size: var(--bs-nav-link-font-size);
  font-weight: 500 !important;
  box-shadow: 0px 0px 5px #07e3f8;
  background-image: linear-gradient(to right, #07e3f8, #026cdc);
  color: #ffffff !important;
  padding: 7px 20px;
}
.btn-connect-wallet:hover {
  border: 2px solid #ffffff;
  /* border: 2px solid #b700ff; */
}
.search-input-box {
  background-color: transparent;
  border: none;
  color: #fff;
  background-color: rgba(59, 59, 59, 0.435);
  border-radius: 16px;
  padding: 0 20px;
  font-size: smaller;
}
.search-input-box-2 {
  font-size: 18px;
  background-color: rgb(27 27 27) !important;
  border: 2px solid #44617d;
  position: sticky;
  top: 0%;
}
.search-input-box-liqui {
  /* background-color: rgb(27 27 27) !important; */
  /* border: 2px solid transparent !important; */
  /* font-weight: 600; */
  /* border: 2px solid transparent !important; */
  font-size: 16px;
  border: 1px solid rgb(92, 92, 92);
  border-radius: 16px;
  padding: 10px 20px;
  background-color: rgb(114 114 114 / 10%);
  color: rgb(255, 255, 255);
}
.search-input-box::placeholder {
  color: #b2b2b2 !important;
}
.search-input-box-2::placeholder {
  color: #7a7a7a !important;
}

.form-control:focus {
  color: #fff;
  background-color: transparent !important;
  border-color: #00cef0;
  outline: 0;
  box-shadow: 0 0 0 0.15rem #00ccf089;
}
.navbar-toggler-icon {
  background-image: url(../../Assets/bars-solid.svg) !important;
}
.search-input-box-2:focus {
  color: #fff;
  background-color: #000 !important;
}
@media (max-width: 550px) {
  .navbar {
    border-bottom: 1px solid rgb(70, 70, 70);
    /* padding-bottom: 20px; */
  }
  /* .nav-explore:hover .hover-nav {
    position: absolute;
  } */
  .nav-explore:hover .hover-nav {
    position: inherit;
  }
  .nav-explore ul {
    width: 10rem;
  }
  .mobile-ui-form {
    align-items: center;
    justify-content: space-between;
  }
  .search-input-box-2 {
    font-size: 15px;
  }
}
