.section-1-pg {
  padding: 60px 0;
}
.section-bg {
  /* background-color: #0e091b; */
  background-color: rgb(19, 19, 19);
}
.section-1-pg h1 {
  padding-bottom: 1.4rem;
  font-weight: 600;
}
.col-half-div {
  background-color: rgb(0 146 255 / 8%);
  padding: 25px;
  border-radius: 20px;
  cursor: pointer;
  color: #00b8fa !important;
}
.col-half-div-2 {
  background-color: rgb(128 114 255/ 12%);
  padding: 25px;
  border-radius: 20px;
  cursor: pointer;
  color: rgb(252, 114, 255) !important;
  position: relative;
  overflow: hidden;
  height: 100%;
}
.col-half-div-3 {
  margin-top: 30px;
  background-color: rgb(0 144 83 / 8%);
  padding: 25px;
  border-radius: 20px;
  cursor: pointer;
  color: rgb(0 202 163) !important;
}
.col-half-div-4 {
  background-color: rgb(136 63 255 / 8%);
  margin-top: 30px;
  padding: 25px;
  border-radius: 20px;
  cursor: pointer;
  color: rgb(184 0 247) !important;
}
.icon-button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  color: #00b8fa !important;
  border: none;
  border-radius: 30px;
  background-color: rgb(19, 19, 19);
  overflow: hidden;
}
.col-half-div-2 .icon-button {
  color: rgb(252, 114, 255) !important;
}
.col-half-div-3 .icon-button {
  color: rgb(0 202 163) !important;
}
.col-half-div-4 .icon-button {
  color: rgb(184 0 247) !important;
}
.button-content {
  display: inline-flex;
  align-items: center;
  transform: translateX(0);
  transition: transform 0.3s ease;
}
.wallet-icon {
  margin-right: 8px;
  opacity: 1;
  transition: opacity 0.3s ease;
}
.arrow-icon {
  opacity: 0;
  transform: translateX(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}
.col-half-div:hover .wallet-icon,
.col-half-div-2:hover .wallet-icon,
.col-half-div-3:hover .wallet-icon,
.col-half-div-4:hover .wallet-icon {
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.col-half-div:hover .button-content,
.col-half-div-2:hover .button-content,
.col-half-div-3:hover .button-content,
.col-half-div-4:hover .button-content {
  transform: translateX(-20px);
  transition: all 0.5s ease-in-out;
}

.col-half-div:hover .arrow-icon,
.col-half-div-2:hover .arrow-icon,
.col-half-div-3:hover .arrow-icon,
.col-half-div-4:hover .arrow-icon {
  opacity: 1;
  transform: translateX(-20px);
  transition: all 0.5s ease-in-out;
}

.col-half-div h2,
.col-half-div-2 h2,
.col-half-div-3 h2,
.col-half-div-4 h2 {
  padding-top: 1rem;
  margin-bottom: 2rem;
}
.box-1-style {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  border-radius: 20px;
  background-color: #131313;
  margin-top: 0.5rem;
}
.box-1-style img {
  width: 30px;
  height: 30px;
}
.box-1-style:hover {
  transform: scale(1.06);
  transition: all 0.3s ease-in-out;
  background-color: #131313b6;
}
.box-1-top,
.box-2-top {
  font-weight: 600;
  font-size: large;
}
.box-1-top span {
  color: rgb(166, 166, 166);
}
.col-half-div-2 img {
  width: 60% !important;
  height: 100%;
  border-radius: 30px;
}

.image-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0 auto;
}

.image-container img {
  position: absolute;
  top: 0;
  left: 20%;
  height: auto;
  transform: translateY(0);
  transition: all 1s ease;
}

.col-half-div-2:hover .image-container img {
  transform: translateY(-65%);
}
@media (max-width: 550px) {
  .box-2-top span i.fa-caret-down,
  .box-2-top span i {
    margin: 0 !important;
  }
  .box-1-top,
  .box-2-top {
    font-size: small;
  }
  .box-1-style img {
    width: 20px;
    height: 20px;
    margin-right: 3px !important;
  }
  .col-half-div,
  .col-half-div-2,
  .col-half-div-3,
  .col-half-div-4 {
    padding: 16px;
  }
}
