.css-fa111x-MuiPaper-root,
.css-3ssuu9-MuiTableCell-root,
.css-1dc80h3-MuiTableCell-root,
.table-bg,
.tbody-bg tr td,
.tbg-scroll {
  background-color: rgb(19, 19, 19) !important;
  color: #fff !important;
}
.css-rx15nw-MuiTableContainer-root,
.tbg-scroll {
  scrollbar-width: none !important;
}
.css-1gak8h1-MuiToolbar-root-MuiTablePagination-toolbar,
.pagination-table {
  color: #fff !important;
  background-color: rgb(19, 19, 19) !important;
}
.css-s09cke-MuiTablePagination-selectLabel,
.css-11cfq65-MuiTablePagination-displayedRows {
  margin-bottom: 0 !important;
}
.css-1jxw05q-MuiTableCell-root,
.css-1roo1yg-MuiTableCell-root,
.thead-bg tr th {
  color: #fff !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  background: rgb(34 34 34) !important;
}
.css-pvu7mv-MuiTable-root {
  border: 0.3px solid grey !important;
}
.react-data-table-container table tbody tr,
.css-3ssuu9-MuiTableCell-root,
.css-1dc80h3-MuiTableCell-root {
  border-color: #8a8a8a6b !important;
}
.css-pvu7mv-MuiTable-root,
.table-bg {
  margin: 0 auto !important;
  width: 80% !important;
}
.css-cdtzx3-MuiButtonBase-root-MuiIconButton-root.Mui-disabled,
.pagination-table.Mui-disabled {
  color: #626262 !important;
}
.css-1gak8h1-MuiToolbar-root-MuiTablePagination-toolbar,
.pagination-table {
  padding-right: 9% !important;
}
.css-1gpuzre-MuiSvgIcon-root-MuiSelect-icon {
  fill: #fff !important;
}
