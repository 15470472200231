* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* background-color: rgb(19, 19, 19); */
}
.text-white {
  color: #fff;
}
.bg-none {
  background-color: none !important;
}
.modal-header .btn-close {
  --bs-btn-close-color: #ffffff;
  --bs-btn-close-bg: url("https://res.cloudinary.com/dbtsrjssc/image/upload/v1721892126/icons8-close-50_d5sipe.png") !important;
}
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #0e091b !important;
}

::-webkit-scrollbar-thumb {
  /* background: #00c2ff; */
  background: #3c3c3c;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #272727;
}
