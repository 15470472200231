.all-platform-pg {
  background-color: rgb(19, 19, 19);
  padding-bottom: 2rem;
  color: #fff;
}
.eth-form-products {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  color: #aaaaaa;
  flex-direction: column;
}
.eth-form-products p {
  font-size: small;
}
.footer-products-details {
  text-align: left;
  background: #11213147;
  padding: 20px;
  border-radius: 10px;
  border: none;
}
.footer-products-details .powarmy-token p {
  font-size: small !important;
}
.footer-products-details img {
  width: 140px;
}
.footer-products-details a {
  text-decoration: none;
  color: #fff;
}
@media (max-width: 880px) {
  .footer-products-center {
    margin: 1rem 0;
  }
}
@media (max-width: 550px) {
  .all-platform-pg {
    padding-top: 2rem;
  }
}
