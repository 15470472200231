.faq-pg {
  padding: 30px 0 50px 0;
  /* padding: 0px 0 50px 0; */
  /* border-bottom: 1px solid rgb(71, 71, 71); */
  background-color: rgb(19, 19, 19);
}
.faq-pg h4 {
  color: rgb(233, 233, 233) !important;
}
.faq-page {
  width: 90%;
  margin: 0 auto;
}
.accordion-button:not(.collapsed) {
  background-color: transparent !important;
  box-shadow: none !important;
  font-weight: 500;
  border-bottom: 0.2px solid rgba(226, 226, 226, 0.401);
  color: rgb(233, 233, 233) !important;
}
.accordion-button:focus {
  box-shadow: none !important;
}
.accordion-button {
  /* font-size: 15px; */
  font-weight: 500;
}
.accordion-body {
  color: rgb(233, 233, 233);
  text-align: justify;
  font-weight: 400;
  font-size: 14px;
}
.faq-pg ul {
  margin-bottom: 0 !important;
}
.accordion {
  --bs-accordion-bg: transparent !important;
}
.accordion-button {
  color: rgb(201, 201, 201) !important;
}
.accordion-button::after {
  background-image: url("https://res.cloudinary.com/dbtsrjssc/image/upload/v1733323406/icons8-chevron-down-24_u3pns4.png") !important;
}
/* .faq-pg h4 {
  padding-bottom: 10px;
}
.general-faq h4 {
  padding-top: 30px;
} */

@media (max-width: 550px) {
  .faq-pg {
    padding: 30px 0px;
  }
}
