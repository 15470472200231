.view-positions-heading {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #fff;
  justify-content: center;
}
.view-positions-pg {
  padding: 60px 0;
}
.vp-pg {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.selected-content {
  /* text-align: center; */
  /* color: #828282; */
  color: #f0f0f0b2;
  height: 100%;
  /* border: 1px solid rgb(59, 59, 59); */
  border: 1px solid rgb(125 125 125);
  /* display: flex; */
  padding: 20px;
  /* align-items: center; */
  /* justify-content: center; */
  border-radius: 20px;
  margin-top: 20px;
  background-color: #11213033;
  backdrop-filter: blur(10px);
}

.page-width-vp {
  width: 60%;
  margin: 0 auto;
}
.dropdown-btn-pool,
.migrate-btn,
.new-positions-btn {
  background-color: transparent;
  padding: 6px 16px;
  border-radius: 10px;
  margin-right: 10px;
  font-size: 14px;
  border: 1px solid grey;
  color: #6d6d6d;
}
.dropdown-btn-pool {
  color: #fff;
}
.dropdown-btn-pool:hover {
  border: 1px solid grey;
}
.migrate-btn:hover {
  background-color: rgba(77, 77, 77, 0.271);
}
.migrate-btn {
  text-decoration: none;
  color: #cbcbcb;
}
.new-positions-btn {
  /* background-color: #40214d81;
  color: #b700ff !important;
  text-decoration: none;
  font-weight: 700; */
  color: #ffffff !important;
  text-decoration: none;
  font-weight: 700;
  box-shadow: 2px 2px 10px black;
  border-radius: 10px;
  background-color: #112130 !important;
}
.new-positions-btn:hover {
  color: #fff !important;
}
.new-positions-relative {
  position: relative;
}
.view-positions-pg .dropdown-item {
  color: #fff !important;
}
.view-positions-pg .dropdown-item:hover {
  background-color: rgba(128, 128, 128, 0.373);
}
.dropdown-menu.show {
  background: #2e2e2e;
}
.account-analytics {
  text-align: left;
}
.created-liquidity-img img,
.manage-all-liquidity img {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
.view-liquidi {
  background-image: linear-gradient(#336ba05d, #00c3ff30);
  /* background-color: #112130; */
  border: 1px solid #00c3ff5b;
  padding: 10px;
  color: #e5e5e5;
  box-shadow: 1px 1px 5px #00c3ff5b;
  border-radius: 10px;
  margin-bottom: 10px;
}
.view-created-liquidity {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 500;
}
.manage-liquidity-btn {
  border: none;
  background-color: transparent;
  color: #e5e5e5;
  font-size: 18px;
  font-weight: 600;
}
.manage-all-liquidity {
  padding-top: 10px;
  color: #bdbdbd;
}
.liquidty-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px 10px;
  font-size: 14px;
  color: #e5e5e5;
}
.created-liquidity-img-mt {
  margin-top: -4px;
}
.liquidity-info-amt img {
  width: 15px;
  height: 15px;
}
@media (max-width: 660px) {
  .page-width-vp {
    width: 100%;
    margin: 0 auto;
  }

  .migrate-btn-a {
    white-space: nowrap;
  }
  .navlink-a-btn-migrate {
    margin-top: 1rem;
    text-align: center;
  }
  .vp-pg {
    display: ruby;
    width: 100%;
    text-align: center;
  }
  .view-positions-pg {
    /* height: 100vh !important; */
  }
}
@media (min-width: 660px) and (max-width: 990px) {
  .page-width-vp {
    width: 80%;
  }
  .view-positions-pg-height,
  .view-positions-pg-migrate {
    /* height: 100vh !important; */
  }
}
.liquidity-info-amt {
  font-weight: 500;
}
@media (min-width: 1600px) {
  .view-positions-pg-height {
    height: 90vh !important;
  }
  .view-positions-pg-height.length-more {
    height: 100% !important;
  }
}
/* 1024 1280 */
@media (min-width: 1025px) and (max-width: 1279px) {
  .view-positions-pg-height.length-less {
    height: 100% !important;
  }
}
@media (min-width: 850px) and (max-width: 1023px) {
  .view-positions-pg-height.length-less {
    height: 100% !important;
  }
}
