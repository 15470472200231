.buy-swap-value {
  height: 20rem;

  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.buy-swap-value .enter-val {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  flex-direction: column !important;
  height: 100%;
}
@media (max-width: 550px) {
  #number-input-send {
    font-size: 3rem;
  }
}
