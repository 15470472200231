.apexcharts-legend-text {
  color: #c0c0c0 !important;
}
.token-pg {
  padding: 60px 0 0 0;
  background-color: rgb(19, 19, 19);
  height: 100vh !important;
}
@media (max-width: 850px) {
  .token-pg {
    height: 100% !important;
  }
  .token-pg .container {
    margin-bottom: 0 !important;
  }
}
